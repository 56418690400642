import { useEffect } from "react";
import * as signalR from "@microsoft/signalr";
import gomelius from "src/api/gomelius";
import { ToastNotification } from "src/util";
import { NotificacaoCard } from "../NotificacaoCard";
import { Position } from "src/util/ToastNotification";
import { useBroadcast } from "src/contexts";

const NotificacaoHub = () => {
  const { sendMessage } = useBroadcast();

  // Função auxiliar para enviar mensagens de forma segura
  const safeSendMessage = (message) => {
    if (sendMessage) {
      try {
        sendMessage(message);
      } catch (error) {
        if (error.name === "InvalidStateError") {
          console.error(
            "BroadcastChannel está fechado. Não foi possível enviar a mensagem.",
            error
          );
        }
      }
    } else {
      console.warn(
        "sendMessage não está disponível ou BroadcastChannel está fechado."
      );
    }
  };

  useEffect(() => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(process.env.REACT_APP_HUB, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
        accessTokenFactory: () => gomelius.getToken(),
      })
      .configureLogging(signalR.LogLevel.Information) // Aumentar o nível de log para capturar mais detalhes
      .build();

    // Log para conexão bem-sucedida
    connection.onreconnected((connectionId) => {
      console.info("Reconectado ao SignalR Hub. ConnectionId:", connectionId);
    });

    connection.onreconnecting((error) => {
      console.warn("Tentando reconectar ao SignalR Hub...", error);
    });

    connection.onclose((error) => {
      console.error("Conexão ao SignalR Hub encerrada.", error);
    });

    // Log e manipulação de mensagem "Nova"
    connection.on("Nova", (registro) => {

      const totalNotificacoes = localStorage.getItem("TotalNotificacao");
      const novoTotal = totalNotificacoes ? parseInt(totalNotificacoes) + 1 : 1;
      localStorage.setItem("TotalNotificacao", novoTotal.toString());

      safeSendMessage({
        type: "notificacao",
        subType: "1",
      });

      const content = (
        <NotificacaoCard
          dados={registro}
          marcarComoLidoClick={false}
          mostrarData={false}
        />
      );
      ToastNotification.info(content, null, Position.TOP_RIGHT, null, false);
    });

    // Log e manipulação de mensagem "Atualizacao"
    connection.on("Atualizacao", (tipo, id, identificador, data) => {

      safeSendMessage({
        type: "atualizacao",
        subType: tipo,
        data: data,
        id: id,
        identifier: identificador,
      });
    });

    // Iniciar conexão
    connection
      .start()
      .then(() => console.info("Conexão ao SignalR Hub estabelecida."))
      .catch((error) =>
        console.error("Erro ao conectar ao SignalR Hub:", error)
      );

    // Parar conexão no cleanup
    return () => {
      connection
        .stop()
        .then(() => console.info("Conexão ao SignalR Hub encerrada."))
        .catch((error) =>
          console.error("Erro ao encerrar conexão com o SignalR Hub:", error)
        );
    };
  }, []);

  return <></>; // Retorna vazio pois o componente não possui conteúdo visual
};

export default NotificacaoHub;
