import { formatDistanceToNow } from "date-fns";
import { ptBR } from "date-fns/locale";
import { useState } from "react";
import { atualizarLayout } from "src/slices/thunks";
import { useDispatch } from "react-redux";
import gomelius from "src/api/gomelius";
import { LogoOrgao } from "src/components/atoms/LogoOrgao";
import { getEnum, notificacaoEnum } from "src/enums";
import React from "react";

// Função para processar o texto e aplicar as formatações
const processText = (text, lineBreakChar = "\r\n") => {
  const boldRegex = /{([^}]+)}/g;
  const italicRegex = /__([^_]+)__/g;

  // Quebrar o texto usando o caractere de quebra de linha personalizado
  return text.split(lineBreakChar).map((line, index) => (
    <React.Fragment key={index}>
      {line
        .split(boldRegex)
        .map((part, i) =>
          i % 2 === 1 ? <strong key={i}>{part}</strong> : part
        )
        .map((part, i) =>
          typeof part === "string"
            ? part
                .split(italicRegex)
                .map((subPart, j) =>
                  j % 2 === 1 ? <em key={j}>{subPart}</em> : subPart
                )
            : part
        )}
      <br />
    </React.Fragment>
  ));
};

export const NotificacaoCard = (props) => {
  const dispatch = useDispatch();
  const [mostrar, setMostrar] = useState(true);
  const { dados, marcarComoLidoClick = true, mostrarData = true } = props;
  const dataCriacao = new Date(dados.dataCriacao);
  const tempoDesdeCriacao = formatDistanceToNow(dataCriacao, {
    addSuffix: true,
    locale: ptBR,
  });

  const enumInfo = getEnum(notificacaoEnum, dados.tipo);
  let avatar;
  switch (dados.tipo) {
    case 1: // AET Iniciada
      {
        avatar = <LogoOrgao type={dados.subTipo} />;
      }
      break;

    default:
      {
        avatar = <LogoOrgao type={dados.subTipo} />;
      }
      break;
  }

  const onNotifactionClickHandler = () => {
    let totalNotificacoes = localStorage.getItem("TotalNotificacao");
    if (totalNotificacoes !== null && totalNotificacoes !== undefined) {
      let novoTotal = parseInt(totalNotificacoes) - 1;
      if (novoTotal < 0) {
        novoTotal = 0;
      }
      localStorage.setItem("TotalNotificacao", novoTotal.toString());
    } else {
      localStorage.setItem("TotalNotificacao", "0");
    }
    gomelius.put(dados.id, null, "Notificacao/MarcarComoLido");
    setMostrar(false);
    dispatch(atualizarLayout());
  };

  if (!mostrar) {
    return null;
  }

  return (
    <a
      className="text-reset notification-item"
      onClick={marcarComoLidoClick ? onNotifactionClickHandler : null}
    >
      <div className="d-flex border-bottom align-items-center">
        <div className="flex-shrink-0">
          <div className="avatar me-3">
            <span className="avatar-title avatar-notificacao bg-soft-success text-success rounded-circle font-size-16">
              {avatar}
            </span>
          </div>
        </div>
        <div className="flex-grow-1">
          <h6 className="mb-1">{enumInfo.descricao}</h6>
          <div className="text-muted">
            <p className="mb-1 font-size-13">
              {processText(dados.mensagem, "\r\n")}

              {enumInfo.badge && (
                <span className={`badge ${enumInfo.badge}`}>
                  {enumInfo.badgeDescription}
                </span>
              )}
            </p>
            {mostrarData && (
              <p className="mb-0 font-size-10 fw-bold">
                <i className="mdi mdi-clock-outline"></i> {tempoDesdeCriacao}
              </p>
            )}
          </div>
        </div>
      </div>
    </a>
  );
};
