import React, { useEffect } from "react";
// import withRouter
import { withRouter} from "src/hocs";

// redux
import { useDispatch } from "react-redux";

import { Column, ColumnSize, Row } from "src/SmartR";
import { AdminUtil } from "src/util";
import { changelayoutMode } from "src/slices/thunks";
import Footer from "./CommonForBoth/Footer";

const LayoutNaoAutenticado = ({ children }: any) => {
  const dispatch = useDispatch();

  function getRandomColor() {
    const colors = [
      "hsl(180, 93%, 6%)",
      "hsl(200, 93%, 50%)",
      "hsl(120, 93%, 50%)",
      "hsl(320, 93%, 70%)",
    ];
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
  }
  function Circle() {
    const randomColor = getRandomColor();

    return (
      <div className="circle-container">
        <div className="circle" style={{ backgroundColor: randomColor }}></div>
      </div>
    );
  }
  useEffect(() => {
    var config = AdminUtil.obterConfiguracao();
    dispatch(changelayoutMode(config.Tema));
  }, [dispatch]);
  useEffect(() => {
    document.body.classList.add("auth");
    return () => {
      document.body.classList.remove("auth");
    };
  }, []);
  return (
    <React.Fragment>
      <div className="base-effect">
        {Array(100)
          .fill(null)
          .map((_, index) => (
            <Circle key={index} />
          ))}
        <div className="authentication-bg min-vh-100">
          <div className="bg-overlay bg-white"></div>
          <div className="container">
            <div className="d-flex flex-column min-vh-100 px-3 pt-4">
              {children}
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(LayoutNaoAutenticado);
