import React from "react";
import { Link } from "react-router-dom";
import { Column, ColumnSize, Row } from "src/SmartR";

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <div className="container-fluid">
          <Row>
            <Column sm={ColumnSize.Col6}>
              {new Date().getFullYear()} © AET Master v{process.env.REACT_APP_VERSION}
            </Column>
            <Column sm={ColumnSize.Col6}>
              <div className="text-sm-end d-none d-sm-block">
                Criado com <i className="mdi mdi-heart text-danger"></i> por{" "}
                <Link
                  to="https://www.aetmaster.com.br/"
                  target="_blank"
                  rel="noreferrer"
                  className="text-reset"
                >
                  Gomelius Sistemas
                </Link>
              </div>
            </Column>
          </Row>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
